import { useChatbotTheme } from 'hooks/auth';
import { steps } from './steps';
import { API_PATH } from 'Helpers/Paths';
import Api from 'Helpers/ApiHandler';
import { useEffect, useState } from 'react';
import ChatBot from '@valabji-wbhr/react-simple-chatbot';
import { useTranslation } from 'react-i18next';
import { botAvatar, defaultAvatar } from './avatars';
import { Tooltip } from 'antd';
const API = new Api();

export const WbhrChatBot = () => {
  const { i18n } = useTranslation();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const chatbotTheme = useChatbotTheme();
  const [chatVisible, setChatVisible] = useState(false);
  const [chatLarge, setChatLarge] = useState(false);
  const [Initialised, setInitialised] = useState(false);
  useEffect(() => {
    if (!Initialised) {
      const url = API_PATH.URL_CHATBOT_INIT;
      API.post(url).then((res) => {
        setInitialised(true);
      });
    }
  });

  return (
    <ChatBot
      floating={true}
      floatingStyle={{
        right: i18n.language === 'en' ? '32px' : 'auto',
        left: i18n.language === 'en' ? 'auto' : '32px',
        display: Initialised ? '' : 'none',
      }}
      opened={chatVisible}
      toggleFloating={() => {
        setChatVisible(!chatVisible);
      }}
      steps={steps({ userData })}
      userDelay={100}
      placeholder=""
      botDelay={100}
      customDelay={300}
      recognitionEnable={false}
      footerStyle={{
        height: '46px',
        margin: '5px',
      }}
      inputStyle={{
        background: chatbotTheme.headerBgColor + '10',
      }}
      submitButtonStyle={{
        height: '46px',
        padding: '10px',
        fill: chatbotTheme.headerBgColor,
        marginTop: '5px',
        marginBottom: '5px',
      }}
      bubbleStyle={{
        maxWidth: '70%',
        minWidth: '5%',
        alignSelf: 'start',
        backgroundColor: '#ffff',
        color: 'black',
        borderStyle: 'solid',
        fontSize: 14,
        borderRadius: '10px 10px 10px 0px',
        borderColor: chatbotTheme.botBubbleColor,
        margin: 5,
        padding: 5,
      }}
      avatarStyle={{ display: 'none', width: 46, height: 46, marginBottom: 5 }}
      botAvatar={botAvatar}
      userAvatar={
        userData && userData.profile ? userData.profile : defaultAvatar
      }
      hideUserText={true}
      headerComponent={
        <div
          style={{
            userSelect: 'none',
            height: 46,
            padding: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: chatbotTheme.headerBgColor,
          }}
        >
          <div
            style={{
              color: chatbotTheme.headerFontColor,
              fontSize: chatbotTheme.headerFontSize,
              fontWeight: chatbotTheme.headerFontWeight,
            }}
          >
            Sara
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              onClick={() => {
                setChatLarge(!chatLarge);
              }}
              style={{
                height: 46,
                width: 32,
                padding: 8,
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Tooltip title="Resize">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    fill="#ffff"
                    d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"
                  />
                </svg>
              </Tooltip>
            </div>
            <div
              onClick={() => {
                setChatVisible(false);
              }}
              style={{
                height: 46,
                width: 32,
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Tooltip title="Hide">
                <svg
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#ffff"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  ></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </Tooltip>
            </div>
          </div>
        </div>
      }
      customStyle={{
        backgroundColor: '#ffff',
        boxShadow: 'none',
        margin: 0,
        paddingTop: 5,
        paddingBottom: 5,
      }}
      contentStyle={{
        height: chatLarge ? '482px' : null,
        transitionProperty: 'transform, width, height',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'ease',
      }}
      style={{
        direction: 'ltr',
        position: 'fixed',
        bottom: '32px',
        transitionProperty: 'transform, width, height',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'ease',
        width: chatLarge ? '70%' : null,
        height: chatLarge ? '600px' : null,
        right: i18n.language === 'en' ? '32px' : 'auto',
        left: i18n.language === 'en' ? 'auto' : '32px',
      }}
    />
  );
};
