import { API_START_POINT, API_PATH } from 'Helpers/Paths';
import { useChatbotTheme } from 'hooks/auth';
import { Tooltip, notification } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Loading } from '@valabji-wbhr/react-simple-chatbot';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { scrollChat } from './utils';
axios.defaults.adapter = require('axios/lib/adapters/http');

export const CustomStep = ({ previousStep, triggerNextStep, steps }) => {
  const chatbotTheme = useChatbotTheme();
  const [data, setData] = useState(null);
  const accessToken = JSON.parse(localStorage.getItem('accessToken'));
  const url = API_START_POINT + API_PATH.URL_CHATBOT;
  const prompt =
    previousStep.value?.toString() || steps['1.1'].value?.toString();
  const dt = {
    prompt,
  };
  useEffect(() => {
    var similar_questions = [];
    const myfunction = async () => {
      await axios
        .post(url, dt, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `TOKEN ${accessToken}`,
          },
          onDownloadProgress: (progressEvent) => {
            var txt = JSON.parse(
              JSON.stringify(progressEvent.target.response),
            ).split('}}');
            txt = JSON.parse(txt[txt.length - 2].toString() + '}}');
            if (txt.response) {
              setData(txt.response);
            }
            if (txt.similar_questions) {
              similar_questions = txt.similar_questions;
            }
            scrollChat();
          },
        })
        .catch((error) => {
          setData(
            'Apologies, I seem to be facing some issues, please try again in a few minutes',
          );
        })
        .finally(() => {
          if (similar_questions.length > 0) {
            triggerNextStep({ value: similar_questions, trigger: '1.1.3' });
          } else {
            triggerNextStep({ trigger: '1.1' });
          }
        });
    };
    myfunction();
    // eslint-disable-next-line
  }, []);
  return !data ? (
    <Loading />
  ) : (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <div
        onClick={() => {
          navigator.clipboard.writeText(data);
          notification.success({ message: 'Copied!' });
        }}
        style={{
          fontSize: 14,
          cursor: 'pointer',
          textAlign: 'start',
          color: chatbotTheme.headerBgColor,
          padding: 5,
        }}
      >
        Sara{' '}
        <Tooltip title="Copy">
          <CopyOutlined style={{ color: '#000' }} />
        </Tooltip>
      </div>
      <div
        style={{
          maxWidth: '70%',
          minWidth: '5%',
          fontSize: 14,
          borderRadius: '10px 10px 10px 0px',
          backgroundColor: chatbotTheme.botBubbleColor,
          whiteSpace: 'pre-line',
          padding: 12,
        }}
      >
        {data}
      </div>
    </div>
  );
};
